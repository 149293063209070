var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { FILTERS_STARTS_WITH } from "@kraftheinz/common";
import _ from "lodash";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "visible": _vm.visible, "title": "Add Comment Maintenance", "width": 832 }, on: { "cancel": _vm.closeModal, "ok": function($event) {
      return handleSubmit(_vm.createNewComment);
    } } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { key: "Country", attrs: { "label": "Country", "data-source": _vm.countries, "source": "country", "source-label": _vm.parseSourceLabel("country", ["country", "countryName"]), "form-item": "", "rules": "required" }, on: { "change": function($event) {
      return _vm.updateQuery("country", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { key: "Facility", attrs: { "label": "Facility", "data-source": _vm.facilities, "source": "facility", "source-label": _vm.parseSourceLabel("facility", ["facility", "facilityName"]), "form-item": "", "rules": "required" }, on: { "change": function($event) {
      return _vm.updateQuery("facility", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { key: "Item", attrs: { "label": "Item", "data-source": _vm.itemCodes, "source": "itemId", "source-label": _vm.parseSourceLabel("itemCode", ["itemCode", "itemDesc"]), "form-item": "", "rules": "required", "all-in-value": "", "custom-search": true }, on: { "change": _vm.onItemCodesChange, "search": _vm.onItemCodeSearch } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "label": "Estimated Cuts", "form-item": "", "rules": "required" }, on: { "change": function($event) {
      return _vm.updateQuery("estimatedCuts", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("date-picker", { attrs: { "label": "Start Date", "form-item": "", "rules": "required", "value": _vm.createForm.startDate, "disabled-date": _vm.disabledStartDate }, on: { "change": function($event) {
      return _vm.onDateChange("startDate", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("date-picker", { attrs: { "label": "End Date", "form-item": "", "rules": "required", "value": _vm.createForm.endDate, "disabled-date": _vm.disabledEndDate }, on: { "change": function($event) {
      return _vm.onDateChange("endDate", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "label": "Reason Code", "data-source": _vm.reasonCodes, "source": "reasonCode", "source-label": _vm.parseSourceLabel("reasonCode", [
      "reasonCode",
      "reasonGroup",
      "reasonCodeDesc"
    ]), "form-item": "", "rules": "required" }, on: { "change": function($event) {
      return _vm.updateQuery("reasonID", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-area", { attrs: { "label": "Reason Desc", "form-item": "" }, on: { "change": function($event) {
      return _vm.updateQuery("reasonDesc", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-area", { attrs: { "label": "Root Cause", "form-item": "" }, on: { "change": function($event) {
      return _vm.updateQuery("rootCause", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-area", { attrs: { "label": "Action Taken", "form-item": "" }, on: { "change": function($event) {
      return _vm.updateQuery("actionRequired", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "label": "Who", "form-item": "" }, on: { "change": function($event) {
      return _vm.updateQuery("actionWho", $event);
    } } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
var AddCommentMaintenance_vue_vue_type_style_index_0_lang = "";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const COUNTRY_NAME_LIST = {
  AU: "Australia",
  NZ: "New Zealand"
};
const __vue2_script$1 = {
  name: "AddCommentMaintenance",
  inject: ["resourceProps"],
  data() {
    const [
      commentProps,
      facilityProps,
      itemCodeProps,
      countriesProps,
      reasonCodesProps
    ] = this.resourceProps;
    return {
      apiUrl: apiUrl$1,
      commentProps,
      facilityProps,
      itemCodeProps,
      countriesProps,
      reasonCodesProps,
      visible: false,
      isHaveData: false,
      createForm: {
        country: ""
      }
    };
  },
  computed: {
    facilities() {
      const arr = [...this.facilityProps.crud.getList()];
      arr.shift();
      return this.isHaveData ? arr : [];
    },
    itemCodes() {
      let arrItemCodes = [...this.itemCodeProps.crud.getList()];
      arrItemCodes = arrItemCodes.map((itemCode, index2) => Object.assign(itemCode, { itemId: index2 }));
      return this.isHaveData ? arrItemCodes : [];
    },
    countries() {
      return this.countriesProps.crud.getList().slice(0, 2).map((country) => __spreadProps(__spreadValues({}, country), {
        countryName: COUNTRY_NAME_LIST[country.country]
      }));
    },
    reasonCodes() {
      return this.reasonCodesProps.crud.getList();
    }
  },
  watch: {
    "createForm.country": function(newVal) {
      if (newVal) {
        this.isHaveData = true;
        this.fetchFacilityList(newVal);
        this.fetchItemCodeList(newVal);
      }
    }
  },
  mounted() {
    this.visible = true;
    this.fetchCountryList();
    this.fetchReasonCodeList();
  },
  methods: {
    disabledStartDate(current) {
      return current && current < this.$moment().subtract(6, "w");
    },
    disabledEndDate(current) {
      return current && current < this.$moment(this.createForm.startDate).startOf("day");
    },
    fetchFacilityList(country) {
      this.fetchList(this.facilityProps, { country });
    },
    fetchReasonCodeList() {
      this.fetchList(this.reasonCodesProps);
    },
    fetchItemCodeList(country) {
      if (country)
        this.fetchList(this.itemCodeProps, { country });
    },
    fetchCountryList() {
      this.fetchList(this.countriesProps);
    },
    fetchList(resourceProps, params = {}) {
      const isCustomQuery = resourceProps.resourceName !== "cfr.phanned-future-cut-comments";
      const query = Object.keys(params).length > 0 ? this.convertQueryString(params) : null;
      resourceProps.crud.deleteFilter("IsInactive");
      resourceProps.crud.fetchList(null, true, true, query, isCustomQuery);
    },
    convertQueryString(params) {
      let query = "";
      for (const [key, value] of Object.entries(params)) {
        query += value ? `${key}=${value}` : "";
      }
      return query;
    },
    generateLabel(option, arrKeyDisplay) {
      let str = "";
      arrKeyDisplay.map((keyItem, keyIndex) => {
        if (keyIndex !== 0)
          str += option[keyItem] ? ` - ${option[keyItem].trim()}` : "";
        else
          str += option[keyItem].trim();
      });
      return str;
    },
    parseSourceLabel(keyCondition, keyDisplay = null) {
      if (Array.isArray(keyDisplay)) {
        return (option) => option[keyCondition] !== "" ? this.generateLabel(option, keyDisplay) : "All";
      } else
        return (option) => option[keyCondition] !== "" ? option[keyDisplay || keyCondition].trim() : "All";
    },
    onItemCodesChange(obj) {
      this.updateQuery("item", obj.itemCode);
    },
    onItemCodeSearch: _.debounce(function(val) {
      this.itemCodeProps.crud.deletePagination();
      this.itemCodeProps.crud.setFilter("ItemCode", {
        operator: FILTERS_STARTS_WITH,
        value: val
      });
      this.fetchItemCodeList(this.createForm.country);
    }, 800),
    onDateChange(key, date) {
      if (key == "startDate") {
        this.updateQuery("endDate", null);
        this.updateQuery("sDateOrig", date);
      }
      this.updateQuery(key, date);
    },
    updateQuery(resourceKey, value) {
      this.createForm = __spreadProps(__spreadValues({}, this.createForm), {
        [resourceKey]: value
      });
      this.commentProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    createNewComment() {
      this.commentProps.crud.submitEntity("create", `${this.apiUrl}/cfr/phanned-future-cut-comments?country=${this.createForm.country}`).then(() => {
        this.commentProps.crud.clearEntity("createForm");
        this.closeModal();
      });
    },
    closeModal() {
      this.visible = false;
      this.isHaveData = false;
      this.$router.push("/cfr/cut-reason-comment");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var AddCommentMaintenance = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("resource", { attrs: { "name": [
    "cfr.phanned-future-cut-comments",
    "cfr.common.facilities",
    "cfr.common.oo-item-codes",
    "cfr.common.oo-countries",
    "cfr.common.reason-codes"
  ], "api-url": _vm.apiUrl } }, [_c("add-comment-maintenance")], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    "add-comment-maintenance": AddCommentMaintenance
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
